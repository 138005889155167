/* Global Styles */
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'colors';

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/fonts/MaterialIcons-Regular.ttf); /* For IE6-8 */
    src: local('Material Icons'), local('MaterialIcons-Regular'),
        url(./assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans'),
        url(./assets/fonts/IBM-Plex-Sans/IBMPlexSans-Text.ttf)
            format('truetype');
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans'),
        url(./assets/fonts/IBM-Plex-Sans/IBMPlexSans-SemiBold.ttf)
            format('truetype');
    font-weight: 550;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans'),
        url(./assets/fonts/IBM-Plex-Sans/IBMPlexSans-Bold.ttf)
            format('truetype');
    font-weight: bold;
}

@include mat.core();
// see http://mcg.mbitson.com
// bg-color: A200 (not from contrast)
// fg-color: A200 from contrast
// selected(accent) text, 4th param in "mat.define-palette" mixin
// also you should set color attribute to needed palette

$blue-palette: (
    50: $bright-blue,
    100: $bright-blue,
    200: $bright-blue,
    300: $bright-blue,
    400: $bright-blue,
    500: $bright-blue,
    600: $bright-blue,
    700: $bright-blue,
    800: $bright-blue,
    900: $bright-blue,
    A100: $bright-blue,
    A200: $bright-blue,
    A400: $bright-blue,
    A700: $bright-blue,
    contrast: (
        50: $bright-blue,
        100: $bright-blue,
        200: $bright-blue,
        300: $bright-blue,
        400: $bright-blue,
        500: $bright-blue,
        600: $bright-blue,
        700: $bright-blue,
        800: $bright-blue,
        900: $bright-blue,
        A100: $bright-blue,
        A200: $white,
        A400: $bright-blue,
        A700: $bright-blue,
    ),
);
$candy-app-theme: mat.define-light-theme(
    (
        color: (
            primary: mat.define-palette($blue-palette, A200, A100, A400, A700),
            accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
        ),
    )
);

// @include mat.all-component-themes($candy-app-theme);
@include mat.core-theme($candy-app-theme);
@include mat.tabs-theme($candy-app-theme);
@include mat.slide-toggle-theme($candy-app-theme);
@include mat.datepicker-theme($candy-app-theme);

@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
