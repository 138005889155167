@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 767px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

.mobile {
    @include desktop {
        display: none;
    }

    @include tablet {
        display: none;
    }
}

.desktop {
    @include mobile {
        display: none;
    }
}
