$technical-alert-blue: #56708c;
$dark-tint: #293138;
$secondary-button: #737373;
$orange: #f28e2b;
$blue: #4e79a7;
$yellow: #fac60c;
$admin-gray: #898583;
$bright-blue: #009dd6;
$light-gray: #dfe3e9;
$red: #d13e40;
$hover-gray: #f1f1f1;
$white: #ffffff;
$black: black;
$gray: gray;
$admin-blue: #1f4166;
$input-stroke: #dfe3e9;
$disabled-input: #e9edf1;
$cardiologist-green: #3d9987;
$icon-purple: #8479a4;
$technical-blue: #56708c;
$light-blue-background: #e4f2f7;
$green: #27bb86;
$dark-green: #5d9b13;
$notification-blue: #4a88ca;
$light-yellow-background: #faf1d4;
$bright-blue-hover: #008cbf;
$bright-blue-pressed: #36abd6;
$secondary-button-hover: #e9eef7;
$pressed-grey: #e3e3e3;
$text-black: #414450;
$text-secondary: #748097;
$text-dark-brown: #736945;
$text-gray: #828282;
$background-gray: #f4f5f7;
$dark-gray: #71737c;
$violet: #323480;
$background-alert-status: #f2f7fd;
