@import 'colors';
@import 'responsive';
@import 'theme';

* {
    font-family: IBM Plex Sans, sans-serif !important;
}

h1 {
    color: $red;
    font-size: 2.5rem;
}

body {
    margin: 0;
    background: $background-gray;
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.main-page {
    height: calc(100vh - 64px);

    @include mobile {
        height: calc(100vh - 56px);
    }
}

.btn-primary {
    background: $bright-blue;
    border: none;
    outline: none;

    &:hover {
        background: $bright-blue-hover;
    }

    &:active {
        background: $bright-blue-pressed;
    }

    &:focus {
        background: $bright-blue-pressed;
        box-shadow: none;
    }
}

.modal-right {
    height: 100vh;
    width: 700px;
    max-width: 100vw;
    margin: 0;
    right: -700px;
    position: absolute;
    transition: transform 1s ease-out !important;
    transform: translate(-700px, 0) !important;

    .modal-content {
        border-radius: 0;
        min-height: 100vh;
        overflow-y: auto;
    }

    .fade:not(.show) & {
        transition: transform 1s ease-out !important;
        transform: translate(700px, 0) !important;
    }
}

.modal-full {
    height: 100vh;
    width: 100%;
    max-width: 100%;
    margin: 0;
    right: -700px;
    position: absolute;
    transition: transform 1s ease-out !important;
    transform: translate(-700px, 0) !important;

    .modal-content {
        border-radius: 0;
        min-height: 100vh;
        overflow-y: auto;
    }

    .fade:not(.show) & {
        transition: transform 1s ease-out !important;
        transform: translate(700px, 0) !important;
    }
}

.anonymize {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.bs-datepicker-head,
.bs-datepicker-body table td span.selected {
    background-color: $bright-blue !important;
}

@include mobile {
    .modal-dialog-centered {
        .modal-content {
            border-radius: 5px;
            height: 60vh;
        }
    }
}

.cdk-overlay-container {
    z-index: 1060;
}

.toastLink > a:hover {
    font-family: IBM Plex Sans, sans-serif !important;
    font-weight: 700;
    text-decoration: underline;
}

.toastLink > a {
    font-family: IBM Plex Sans, sans-serif !important;
    color: white;
    font-weight: 550;
    text-decoration: underline;
}

.mat-select-panel {
    background: $white;
}

.mat-menu-panel {
    background: $white;
}

.custom-tooltip {
    padding: 10px;
    height: 24px;
}

input[type='password']::-ms-reveal {
    display: none;
}

.bg-white {
    background: $white;
}

.loading-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-check:checked + .btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
    color: $white;
    text-transform: uppercase;
    background-color: $bright-blue;
    border: none;
    box-shadow: none;
}

input[type='checkbox']:checked {
    border: none;
    background-color: $bright-blue;
    box-shadow: none;
}

.form-check-input {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0) !important;
}

input[type='radio']:checked {
    border: none;
    background-color: $bright-blue;
    box-shadow: none;
}

.mat-button-toggle {
    background-color: $white;
    color: $text-secondary;
}

.mat-button-toggle:hover {
    background-color: $bright-blue;
    color: $white !important;
}

.mat-button-toggle-checked {
    background-color: $bright-blue;
    color: $white !important;
}

.loader-dual-ring {
    &:after {
        content: ' ';
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    &.icon-color:after {
        border-color: #2199e8 transparent #2199e8 transparent;
    }
}

.small .loader-dual-ring:after {
    width: 13px;
    height: 13px;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-padding {
    padding: 8px 16px;
}

.pac-container {
    z-index: 10000 !important;
}
